import React from "react";
import Slider from "../../components/Slider/SlideShow";
import Mission from "../../components/Mission/Mission";
import Machines from "../../components/Machines/Machines";
import Testimonials from "../../components/Testimonials/Testimonials";
import Media from "../../components/Media/Media";
import Brands from "../../components/Brands/Brands";
import styles from "./Home.module.css";
import Footer from "../../components/Footer/Footer";

export default function Home({ navigation }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Slider />
      <Mission />
      <Machines />
      <Testimonials />
      <Brands />
      <Media />
      <Footer />
    </div>
  );
}
