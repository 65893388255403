import {
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  collection,
} from "@firebase/firestore";
import { FIRESTORE_DB } from "../firebase/firebase";

export default async function getUsers() {
  const usersCol = collection(FIRESTORE_DB, "users");
  const usersSnapshot = await getDocs(usersCol);
  const usersList = usersSnapshot.docs.map((doc) => doc.data());
  return usersList;
}

export async function getQuotes() {
  const quotesCol = collection(FIRESTORE_DB, "quotes");
  const quotesSnapshot = await getDocs(quotesCol);
  const quotesList = quotesSnapshot.docs.map((doc) => doc.data());
  return quotesList;
}

async function createUser(userData) {
  try {
    const docRef = await addDoc(collection(FIRESTORE_DB, "users"), userData);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function createOrder(orderData) {
  console.log(orderData.client.id_cliente);

  let objeto = {
    id_cliente: orderData.client.id_cliente,
    fecha_actual: "09/10/2023",
    fecha_validez: "31/10/2023",
    estado: "Pendiente",
    numero_orden: "0001",
    // items,
  };
  // try {
  //   const docRef = await addDoc(collection(FIRESTORE_DB, "quotes"), userData);
  //   console.log("Document written with ID: ", docRef.id);
  // } catch (e) {
  //   console.error("Error adding document: ", e);
  // }
}

async function updateUser(id, userData) {
  const usersCollectionRef = doc(FIRESTORE_DB, "users", id);
  console.log(userData);
  console.log("hola");
  updateDoc(usersCollectionRef, userData)
    .then((response) => {
      alert("updated");
    })
    .catch((error) => {
      console.log(error.message);
    });
}

async function getUserByID(id) {
  if (!id) return;
  console.log("getuser", id);

  const q = query(
    collection(FIRESTORE_DB, "users"),
    where("id", "==", id)
  );

  const querySnapshot = await getDocs(q);

  var client = null;

  querySnapshot.forEach((doc) => {
    client = doc.data();
    // console.log(doc.id)
    client.id = doc.id;
  });

  console.log("Datos del cliente:", client);

  return client;
}

export { getUsers, getUserByID, createUser, updateUser, createOrder };
