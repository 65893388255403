import "./App.css";
import Home from "./views/Home/Home";
import NavBar from "./components/NavBar/NavBar";
import { Routes, Route } from "react-router-dom";
import Login from "./views/Login/Login";
import CreateAccount from "./views/CreateAccount/CreateAccount";
import NoMatch from "./views/NoMatch/NoMatch";
import CreateClient from "./views/CreateClient/CreateClient";
import CreateQuote from "./views/CreateQuote/CreateQuote";
import Dashboard from "./views/Dashboard/Dashboard";
import EditClient from "./views/EditClient/EditClient";
import { AppStateProvider } from "./AppState"; 

function App() {
  return (
    <div className="App">
    <AppStateProvider>
      <Routes>
        <Route path="/">
          <Route path="login" element={<Login />} />
          <Route
            index
            element={
              <>
                <NavBar />
                <Home />
              </>
            }
          />
          <Route path="create" element={<CreateAccount />} />
          <Route path="new-client" element={<CreateClient />} />
          <Route path="edit-client/:id" element={<EditClient />} />
          <Route path="new-quote" element={<CreateQuote />} />
          <Route path="dashboard/*" element={<Dashboard />} />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      </AppStateProvider>
    </div>
  );
}

export default App;
