import React from "react";
import styles from "./Mission.module.css";
import { WrenchIcon, CpuChipIcon, UserIcon } from "@heroicons/react/24/solid";
import bigPhoto from "../../assets/banner-01.jpg";
import smallPhoto1 from "../../assets/banner-02.webp";
import smallPhoto2 from "../../assets/banner-03.jpg";

export default function Mission() {
  return (
    <div className={styles.container}>
      <div className={styles.internal}>
        <div className={styles.infoContainer}>
          <h2>Nuestra misión</h2>
          <p>
            Prestar servicios de excelencia a las necesidades y expectativas de
            nuestros clientes.
          </p>
          <h2>Nuestro objetivo</h2>
          <p>
            Agregar valor a las empresas aportando nuestra experiencia en la
            implementación de soluciones viables y efectivas. Brindar servicios
            de características diferenciales que logren satisfacer los
            requerimientos de los proyectos, cumpliendo con las normas de
            seguridad vigentes.
            <span>Ser reconocidos como el mejor aliado para su negocio</span>
          </p>
          <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                gap: 12,
              }}
            >
              <WrenchIcon width={20} height={20} />
              <p>Atención in situ</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                gap: 12,
              }}
            >
              <CpuChipIcon width={20} height={20} />
              <p>Tecnología de vanguardia.</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                gap: 12,
              }}
            >
              <UserIcon width={20} height={20} />
              <p>Adaptación a sus necesidades</p>
            </div>
          </div>
        </div>
        <div className={styles.photosContainer}>
          <div className={styles.bigPhoto}>
            <img src={bigPhoto} alt="" />
          </div>
          <div className={styles.smallPhotos}>
            <img src={smallPhoto1} alt="" />
            <img src={smallPhoto2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
