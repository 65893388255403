import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { AiOutlineUserAdd } from "react-icons/ai";
import styles from "./ClientsList.module.css";
import { getUsers } from "../../handles/handlesubmit";
import { useAppState, useDispatch } from "../../AppState";

export default function ClientsList() {
  const { state } = useAppState();
  const { clients, searchTerm } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (!clients) {
        const tempClients = await getUsers();
        dispatch({ type: "SET_CLIENTS", payload: tempClients });
      }
    }
    fetchData();
  }, [clients, dispatch, searchTerm]);

  return (
    <>
      <div className={styles.header}>
        <h5>CLIENTES</h5>

        <div className={styles.search}>
          <span className={styles.iconsearch}>
            <FiSearch />
          </span>
          <input
            className={styles.inputsearch}
            type="text"
            placeholder="Buscar por CUIT, Razon social o Contacto"
            value={searchTerm}
            onChange={(e) => dispatch({ type: "SET_SEARCH_TERM", payload: e.target.value })} // Actualiza el término de búsqueda
          />
        </div>

        <Link to={"new-client"} className={styles.linkbtn}>
          <button className={styles.btn}>
            <AiOutlineUserAdd className={styles.iconadd} />
            Nuevo cliente
          </button>
        </Link>
      </div>

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.column}>
            <p>Razon social</p>
          </div>
          <div className={styles.column}>
            <p>CUIT</p>
          </div>
          <div className={styles.column}>
            <p>Numero de cliente</p>
          </div>
          <div className={styles.column}>
            <p>Contacto</p>
          </div>
          <div className={styles.column}>
            <p>Telefono</p>
          </div>
          <div className={styles.column}>
            <p>Localidad</p>
          </div>
        </div>
        <div className={styles.divisor} />

        {state.clients &&
          clients
            .filter((client) => {
              const searchValue = searchTerm.toLowerCase();
              return (
                client.cuit.toLowerCase().includes(searchValue) ||
                client.razon_social.toLowerCase().includes(searchValue) ||
                client.contacto.toLowerCase().includes(searchValue)
              );
            })
            .map((client) => (
              <>
                <div className={styles.tableRow}>
                  <div className={styles.column}>
                    <p className={styles.responsiveTitle}>Razon social: </p>
                    <p>{client.razon_social}</p>
                  </div>
                  <div className={styles.column}>
                    <p className={styles.responsiveTitle}>CUIT: </p>
                    <p>{client.cuit}</p>
                  </div>
                  <div className={styles.column}>
                    <p className={styles.responsiveTitle}>ID: </p>
                    <p>{client.id_cliente}</p>
                  </div>
                  <div className={styles.column}>
                    <div>
                      <p className={styles.responsiveTitle}>Contacto: </p>
                      <p>{client.contacto}</p>
                      <p className={styles.responsiveTitle}>Email: </p>
                      <p className={styles.email}>{client.email}</p>
                    </div>
                  </div>
                  <div className={styles.phoneColumn}>
                    <p className={styles.responsiveTitle}>Telefono: </p>
                    <p>{client.telefono}</p>
                  </div>
                  <div className={styles.column}>
                    <p className={styles.responsiveTitle}>Localidad: </p>
                    <p>{client.localidad}</p>
                  </div>
                  <div>
                    <Link to={`edit-client/${client.id}`} className={styles.linkbtn}>
                      <button>Editar</button>
                    </Link>
                  </div>
                </div>
                <div className={styles.divisor} />
              </>
            ))}
      </div>
    </>
  );
}
