import React from "react";
import styles from "./Brands.module.css";
import YPF from "../../assets/Logo_de_YPF.png";
import GOLDFIELD from "../../assets/gold-fields-logo.svg";
import AM from "../../assets/LogoAM.png";
import ALLIED from "../../assets/Allied.jpeg";
import PASCUAL from "../../assets/logo-mauer.png";
import MAUER from "../../assets/logo-pascual.png";
import ReactPlayer from "react-player";

export default function Brands() {
  return (
    <div class={styles.container}>
      <h4>Videos de Facebook</h4>
      <ReactPlayer
        url="https://www.facebook.com/lavacalacteacom/videos/932063017980844"
        controls
        width={400}
      />

      <h3>Estas empresas confian en nosotros</h3>
      <section class={styles.flexContainer}>
        <div>
          <img src={YPF} alt="Logo YPF" />
        </div>
        <div>
          <img src={GOLDFIELD} alt="Logo YPF" />
        </div>
        <div>
          <img src={AM} alt="Logo YPF" />
        </div>
        <div>
          <img src={ALLIED} alt="Logo YPF" />
        </div>
        <div>
          <img src={PASCUAL} alt="Logo YPF" />
        </div>
        <div>
          <img src={MAUER} alt="Logo YPF" />
        </div>
        <div>
          <img src={YPF} alt="Logo YPF" />
        </div>
        <div>
          <img src={GOLDFIELD} alt="Logo YPF" />
        </div>
        <div>
          <img src={AM} alt="Logo YPF" />
        </div>
        <div>
          <img src={ALLIED} alt="Logo YPF" />
        </div>
        <div>
          <img src={PASCUAL} alt="Logo YPF" />
        </div>
        <div>
          <img src={MAUER} alt="Logo YPF" />
        </div>
      </section>
    </div>
  );
}
