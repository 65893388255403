import React from "react";
import styles from "./Footer.module.css";
import facebook from "../../assets/bxl-facebook.svg";
import twitter from "../../assets/bxl-twitter.svg";
import instagram from "../../assets/instagram-logo.svg";
import youtube from "../../assets/youtube-logo.svg";

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.internal}>
        <div className={styles.logo}>Logo</div>
        <div className={styles.quicklinks}>
          <h5>Quicklinks</h5>
          <p>Home</p>
          <p>Sobre nosotros</p>
          <p>Porque elegirnos</p>
          <p>Otros</p>
          <p>Contactenos</p>
        </div>
        <div className={styles.contact}>
          <h5>Contactenos</h5>
          <p>Consultas e información: 123-456-7890</p>
          <p>Atención al cliente: 123-456-7890</p>
        </div>
        <div className={styles.social}>
          <h5>Social</h5>
          <div>
            <img src={facebook} alt="facebook logo" />
            <img src={instagram} alt="instagram logo" />
            <img src={twitter} alt="twitter logo" />
            <img src={youtube} alt="youtube logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
