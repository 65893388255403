import React, { createContext, useContext, useReducer } from "react";

// Define el contexto
const AppStateContext = createContext();

// Define los tipos de acción
const SET_CLIENTS = "SET_CLIENTS";
const SET_SEARCH_TERM = "SET_SEARCH_TERM";
const UPDATE_CLIENT = "UPDATE_CLIENT"; // Nueva acción para actualizar un cliente

// Define el estado inicial
const initialState = {
  clients: null,
  searchTerm: "",
};

// Define el reductor
function appStateReducer(state, action) {
  switch (action.type) {
    case SET_CLIENTS:
      return { ...state, clients: action.payload };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    case UPDATE_CLIENT: // Maneja la acción para actualizar un cliente
      const updatedClients = state.clients.map((client) => {
        if (client.id === action.payload.id) {
          return action.payload; // Actualiza el cliente con los nuevos datos
        }
        return client;
      });
      return { ...state, clients: updatedClients };
    default:
      return state;
  }
}

// Define el proveedor de estado
export function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
}

// Define un hook personalizado para acceder al estado global
export function useAppState() {
  return useContext(AppStateContext);
}

// Define un hook personalizado para acceder a la función de despacho
export function useDispatch() {
  const { dispatch } = useAppState();
  return dispatch;
}
