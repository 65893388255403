import React from "react";
import styles from "./Testimony.module.css";

export default function Testimony({ text, url, name }) {
  return (
    <div className={styles.testimony}>
      {text && <p>{text}</p>}
      {url && <img src={url} alt={name} />}
      {name && <span>{name}</span>}
    </div>
  );
}
