import React from "react";
import styles from "./Testimonials.module.css";

import Testimony from "../Testimony/Testimony";

export default function Testimonials() {
  return (
    <div className={styles.container}>
      <div className={styles.internal}>
        <div>
          <h2>Testimonios</h2>
        </div>
        <div className={styles.testimonies}>
          <Testimony
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et."
            }
            url={"https://i.pravatar.cc/200"}
            name={"Nombre apellido"}
          />
          <Testimony
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et."
            }
            url={"https://i.pravatar.cc/200"}
            name={"Nombre apellido"}
          />
          <Testimony
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et."
            }
            url={"https://i.pravatar.cc/200"}
            name={"Nombre apellido"}
          />
        </div>
      </div>
    </div>
  );
}
