import React from "react";
import styles from "./CreateAccount.module.css";
import bulldozer from "../../assets/bulldozer.jpg";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

export default function CreateAccount() {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={bulldozer} alt={"bulldozer"} />
      </div>
      <div className={styles.formContainer}>
        <form className={styles.login}>
          <h1 style={{ color: "Black" }}>LSUNGEN</h1>
          <h5 style={{ color: "Black" }}>Llene con sus datos por favor</h5>
          <div className={styles.login__field}>
            {/* <i className={styles.login__icon}></i> */}
            <input
              type="text"
              className={styles.login__input}
              placeholder="CUIT"
            />
          </div>
          <div className={styles.login__field}>
            {/* <i className={styles.login__icon}></i> */}
            <input
              type="text"
              className={styles.login__input}
              placeholder="NOMBRE DE LA EMPRESA"
            />
          </div>
          <div className={styles.login__field}>
            {/* <i className="login__icon fas fa-lock"></i> */}
            <input
              type="password"
              className={styles.login__input}
              placeholder="CONTRASEÑA"
            />
          </div>
          <Link
            className={styles.login__submit}
            to="/table"
            // className={styles.active}
          >
            Crear la nueva cuenta
          </Link>
          <Link
            className={styles.login__submit}
            to="/login"
            // className={styles.active}
          >
            Cancelar la creacion
          </Link>
          {/* <button className={styles.login__submit}>
            <span className={styles.button__text}></span>
            <i className="button__icon fas fa-chevron-right"></i>
          </button> */}
        </form>
        {/* <Link
          style={{ textDecoration: "none", paddingBottom: 20 }}
          to="/create"
          className={styles.active}
        >
          ¿Es un usuario nuevo? Crear cuenta
        </Link> */}

        {/* <a href="#" className={styles.forgotPass}>
          ¿Se olvidó su contraseña?
        </a> */}
      </div>
    </div>
  );
}
