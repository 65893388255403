import React, { useState } from "react";
import styles from "./Dashboard.module.css";
import ClientList from "../ClientsList/ClientsList";
import CreateClient from "../CreateClient/CreateClient";
import OrdersList from "../OrdersList/OrdersList";
import CreateQuote from "../CreateQuote/CreateQuote";
import { Link, Route, Routes } from "react-router-dom";
import EditClient from "../EditClient/EditClient";

import icon from "../../assets/icon.png";
import chart from "../../assets/chart.png";
import engranaje from "../../assets/engranaje.png";

export default function Dashboard() {
  return (
    <div className={styles.container}>
      <div className={styles.sideBarContainer}>
        <h5>DASHBOARD</h5>
        <h5>Bienvenido Ezequiel</h5>

        <div className={styles.buttonList}>
          <Link to={"clients"} style={{ textDecoration: "none" }}>
            <button className={styles.selectedButton}>
              <div>
                <img src={icon} className={styles.icon} alt="" />
              </div>
              <p className={styles.buttonTitle}>Clientes</p>
            </button>
          </Link>

          <Link to={"orders"} style={{ textDecoration: "none" }}>
            <button className={styles.button}>
              <div className={styles.iconBG}>
                <img src={chart} className={styles.icon} alt="" />
              </div>
              <p className={styles.buttonTitle}>Ordenes</p>
            </button>
          </Link>

          {/* <Link to={"orders"} style={{ textDecoration: "none" }}> */}
          <button className={styles.button}>
            <div className={styles.iconBG}>
              <img src={engranaje} className={styles.icon} alt="" />
            </div>
            <p className={styles.buttonTitle}>Administracion</p>
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div className={styles.mainContainer}>
        <Routes>
          <Route path="clients" element={<ClientList />} />
          <Route path="clients/new-client" element={<CreateClient />} />
          <Route path="clients/edit-client/:id" element={<EditClient />} />
          <Route path="orders" element={<OrdersList />} />
          <Route path="orders/new-order" element={<CreateQuote />} />
        </Routes>
      </div>
    </div>
  );
}
