import React, { useEffect, useState } from "react";
import styles from "./EditClient.module.css";
import { updateUser, getUserByID } from "../../handles/handlesubmit";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "../../AppState"; // Importa la función useDispatch del estado global


// const { id } = useParams();

// const initialState = {
//   id_cliente: "",
//   razon_social: "",
//   cuit: "",
//   pass:"",
//   contacto: "",
//   email: "",
//   telefono: "",
//   domicilio: "",
//   localidad: "",
//   provincia: "",
//   cod_pos: "",
// };

export default function EditClient() {
  const navigate = useNavigate();
  const { id } = useParams(); // Obteniendo el ID del cliente de la URL
  const [user, setUser] = useState(null);
  const dispatch = useDispatch(); // Obtén la función dispatch para actualizar el estado
  console.log(id);

  useEffect(() => {
    // Cargar datos del cliente al montar el componente
    async function fetchData() {
      const userData = await getUserByID(id);
      setUser(userData);
    }
    fetchData();
  }, [id]);

  const handleInputs = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    await updateUser(user.id, user); // Actualiza el usuario en el servidor
  
    fetchData();

    dispatch({ type: "UPDATE_CLIENT", payload: user }); // Actualiza el estado global con los nuevos datos del cliente
  
    navigate(-1); // Redirige a la página anterior
  };

  const fetchData = async () => {
    const userData = await getUserByID(id);
    setUser(userData);
  };

  
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h3>Modificar datos de cliente</h3>
      {user &&
        <form
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 8,
          maxWidth: 1200,
        }}
      >
        <div>
          <label htmlFor="id_cliente">Numero de cliente: </label>
          <input
            type="text"
            className={styles.input}
            id="id_cliente"
            name="id_cliente"
            onChange={(e) => handleInputs(e)}
            value={user.id_cliente}
          />
        </div>
        <div>
          <label htmlFor="razon_social">Razon social: </label>
          <input
            type="text"
            className={styles.input}
            id="razon_social"
            name="razon_social"
            onChange={(e) => handleInputs(e)}
            value={user.razon_social}
          />
        </div>
        <div>
          <label htmlFor="cuit">Cuit: </label>
          <input
            type="text"
            className={styles.input}
            onChange={(e) => handleInputs(e)}
            value={user.cuit}
            id="cuit"
            name="cuit"
          />
        </div>
        <div>
          <label htmlFor="pass">Contraseña: </label>
          <input
            type="text"
            className={styles.input}
            onChange={(e) => handleInputs(e)}
            value={user.pass}
            id="pass"
            name="pass"
          />
        </div>
        <div>
          <label htmlFor="contacto">Contacto: </label>
          <input
            type="text"
            className={styles.input}
            id="contacto"
            name="contacto"
            onChange={(e) => handleInputs(e)}
            value={user.contacto}
          />
        </div>
        <div>
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            className={styles.input}
            id="email"
            name="email"
            onChange={(e) => handleInputs(e)}
            value={user.email}
          />
        </div>
        <div>
          <label htmlFor="telefono">Telefono: </label>
          <input
            type="text"
            className={styles.input}
            id="telefono"
            name="telefono"
            onChange={(e) => handleInputs(e)}
            value={user.telefono}
          />
        </div>
        <div>
          <label htmlFor="domicilio">Domicilio: </label>
          <input
            type="text"
            className={styles.input}
            id="domicilio"
            name="domicilio"
            onChange={(e) => handleInputs(e)}
            value={user.domicilio}
          />
        </div>
        <div>
          <label htmlFor="localidad">Localidad: </label>
          <input
            type="text"
            className={styles.input}
            id="localidad"
            name="localidad"
            onChange={(e) => handleInputs(e)}
            value={user.localidad}
          />
        </div>
        <div>
          <label htmlFor="provincia">Provincia: </label>
          <input
            type="text"
            className={styles.input}
            id="provincia"
            name="provincia"
            onChange={(e) => handleInputs(e)}
            value={user.provincia}
          />
        </div>
        <div>
          <label htmlFor="cod_pos">Codigo postal: </label>
          <input
            type="text"
            className={styles.input}
            id="cod_pos"
            name="cod_pos"
            onChange={(e) => handleInputs(e)}
            value={user.cod_pos}
          />
        </div>
      </form>
      }
      <div style={{ display: "flex", gap: 16 }}>
        <Link to={"clients"} style={{ textDecoration: "none" }}>
          <button
            type="cancel"
            className={styles.cancel}
            onClick={() => navigate(-1)}
          >
            Cancelar
          </button>
        </Link>
        <button
          type="submit"
          onClick={(e) => submitHandler(e)}
          className={styles.submit}
        >
          Modificar
        </button>
      </div>
    </div>
  );
}
