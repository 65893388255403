import React from "react";
import styles from "./Machines.module.css";
import hammer from "../../assets/hammer.png";
import retro from "../../assets/retro.jpg";
import tren from "../../assets/tren.png";
import trituradora from "../../assets/trituradora.jpeg";
import overhaul from "../../assets/overhaul.jpg";
import bombas from "../../assets/bombas.jpg";
import {
  ArrowRightOnRectangleIcon,
  ChevronDoubleRightIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";

export default function Machines() {
  return (
    <div className={styles.container}>
      <div className={styles.internal}>
        <h2>Servicio de equipamientos</h2>
        <div className={styles.photosContainer}>
          <div className={styles.card}>
            <img src={hammer} className={styles.photo} />
            <h4>Martillos hidráulicos</h4>
            <div className={styles.items}>
              <p>Servicios preventivos por hora equipo.</p>
              <p>Servicios preventivos fijos mensuales.</p>
              <p>Relevamiento, informe tecnico y determinacion de vida útil.</p>
              <p>Aplicaciones overhaul.</p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={retro} className={styles.photo} />
            <h4>Equipos viales y mineros</h4>
            <div className={styles.items}>
              <p>Servicios preventivos por hora equipo.</p>
              <p>Servicios preventivos fijos mensuales.</p>
              <p>Relevamiento, informe tecnico y determinacion de vida útil.</p>
              <p>Aplicaciones overhaul.</p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={trituradora} className={styles.photo} />
            <h4>Cribadoras y trituradoras moviles</h4>
            <div className={styles.items}>
              <p>Servicios preventivos por hora equipo.</p>
              <p>Servicios preventivos fijos mensuales.</p>
              <p>Relevamiento, informe tecnico y determinacion de vida útil.</p>
              <p>Aplicaciones overhaul.</p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={tren} className={styles.photo} />
            <h4>Reparaciones de trenes de potencia</h4>
            <div className={styles.items}>
              <p>Convertidores</p>
              <p>Cajas</p>
              <p>Diferenciales</p>
              <p>Reductores</p>
              <p>Tándem</p>
              <p>Mandos finales </p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={overhaul} className={styles.photo} />
            <h4>Overhaul de trenes rodantes</h4>
            <div className={styles.items}>
              <p>Cadenas</p>
              <p>Rodillos superiores</p>
              <p>Rodillos inferiores</p>
              <p>Ruedas guias</p>
              <p>Coronas cavillas</p>
              <p>Segmentos</p>
              <p>Zapatas</p>
              <p>Pernos / Pasadores</p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={bombas} className={styles.photo} />
            <h4>Hidráulica</h4>
            <div className={styles.items}>
              <p>Bombas</p>
              <p>Cilindros</p>
              <p>Bancos de válvulas</p>
              <p>Motores</p>
              <p>Direccion hidrostatica orbitrol</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 12,
          }}
        >
          <h3 style={{ color: "white", fontSize: 32, paddingTop: 12 }}>
            Contratar servicio
          </h3>
          <ArrowRightOnRectangleIcon width={30} height={30} color="white" />
        </div>
      </div>
    </div>
  );
}
