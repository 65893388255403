import React from "react";
import styles from "./NavBar.module.css";
import { Link } from "react-router-dom";

const linkStyle = {
  textDecoration: "none",
};

export default function NavBar() {
  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === styles.topnav) {
      x.className += " " + styles.responsive;
    } else {
      x.className = styles.topnav;
    }
  }

  return (
    <>
      <div className={styles.topnav} id="myTopnav">
        <a href="#home">LSUNGEN</a>
        <Link style={linkStyle} to="/login" className={styles.active}>
          Entrar
        </Link>

        <a
          href="javascript:void(0);"
          className={styles.icon}
          onClick={() => myFunction()}
        >
          <i className="fa fa-bars"></i>
        </a>
      </div>
    </>
  );
}

/*
<header>
      <div className="headerRow" style={{ backgroundColor: "rgb(14, 13, 13)" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 auto",
            padding: " 0 3vw",
            width: "100vw",
            color: "white",
            height: "100px",
            maxWidth: 1440,
          }}
        >
          <div style={{ display: "flex", gap: 24 }}>
            <h2 style={{ color: "white" }}>LSUNGEN</h2>
            <button
              style={{ all: "unset", fontWeight: 700, paddingLeft: "54px" }}
            >
              Home
            </button>
            <button style={{ all: "unset", fontWeight: 700 }}>About</button>
            <button style={{ all: "unset", fontWeight: 700 }}>Contact</button>
          </div>
          <div>
            <button
              style={{
                all: "unset",
                cursor: "pointer",
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              Ingresar
            </button>
          </div>
        </div>
      </div>
    </header> */
