import React from "react";
import styles from "./Media.module.css";
import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import { defineCustomElements } from "vidstack/elements";

defineCustomElements();

export default function Media() {
  return (
    <div className={styles.container}>
      <div className={styles.internal}>
        <div className={styles.videoContainer}>
          <iframe
            src="https://www.youtube.com/embed/OjKhiGiJJgI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.textContainer}>
          <div>
            <h3>Titulo sobre informacion importante</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut. Aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
          <button>Boton</button>
        </div>
      </div>
    </div>
  );
}
