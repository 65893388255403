import React, { useEffect, useState } from "react";
import styles from "./CreateQuote.module.css";
import logo from "../../assets/logo_lsungen.png";
import { createOrder, getUserByID } from "../../handles/handlesubmit";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function CreateQuote() {
  const navigate = useNavigate();
  const [client, setClient] = useState({});
  const [id, setId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const temp = await getUserByID(id);
      setClient(temp);
    }

    fetchData();
  }, [id]);

  const addItem = (event) => {
    event.preventDefault();

    let itemId = document.getElementById("item_id");
    let itemDescripcion = document.getElementById("item_descripcion");
    let itemCantidad = document.getElementById("item_cantidad");
    let itemPrecio = document.getElementById("item_precio");

    let id = itemId.value;
    let descripcion = itemDescripcion.value;
    let cantidad = itemCantidad.value;
    let precio = itemPrecio.value;

    setItems((prev) => [
      ...prev,
      {
        id,
        descripcion,
        cantidad,
        precio,
      },
    ]);

    document.getElementById("item_id").value = "";
    document.getElementById("item_descripcion").value = "";
    document.getElementById("item_cantidad").value = "";
    document.getElementById("item_precio").value = "";
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    await createOrder({ client, items });
    // await createUser(user);
    // navigate(-1);
  };

  // Fecha - Exportar funcion
  const date = new Date();
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let day = String(date.getDate()).padStart(2, "0");

  let quote_number = "00000000";

  const handleClientId = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      setId(event.target.value);
    }
  };

  return (
    <div className={styles.container}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div className={styles.labels}>
            <label>Nº cliente:</label>
            <input placeholder="9999" onKeyDown={(e) => handleClientId(e)} />
          </div>

          <div className={styles.labels}>
            <label>R.SOCIAL:</label>
            <label>{client?.razon_social && client.razon_social}</label>
          </div>

          <div className={styles.labels}>
            <label>CUIT:</label>
            <label>{client?.cuit && client.cuit}</label>
          </div>

          <div className={styles.labels}>
            <label>Fecha validez:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <form onSubmit={(e) => addItem(e)}>
            <div className={styles.labels}>
              <label>Agregar ID</label>
              <input id="item_id" placeholder="000" />
            </div>

            <div className={styles.labels}>
              <label>Agregar item</label>
              <input
                placeholder="Reparacion de maquinaria"
                id="item_descripcion"
              />
            </div>

            <div className={styles.labels}>
              <label>Cantidad</label>
              <input placeholder="25" id="item_cantidad" />
            </div>

            <div className={styles.labels}>
              <label>Precio</label>
              <input placeholder="4000" id="item_precio" />
            </div>
            <button type="submit">Agregar</button>
          </form>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {items &&
          items.map((item, index) => (
            <div key={index} style={{ display: "flex", gap: 18 }}>
              {console.log(item)}
              <label>{item.id}</label>
              <label>{item.descripcion}</label>
              <label>{item.precio}</label>
              <label>{item.cantidad}</label>
            </div>
          ))}
        <div>
          <Link to={"clients"} style={{ textDecoration: "none" }}>
            <button
              type="cancel"
              className={styles.cancel}
              onClick={() => navigate(-1)}
            >
              Cancelar
            </button>
          </Link>

          {/* <Link to={"clients"} style={{ textDecoration: "none" }}> */}
          <button onClick={(e) => submitHandler(e)} className={styles.submit}>
            Crear
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
