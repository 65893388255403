import React, { useEffect, useState } from "react";
import { getQuotes } from "../../handles/handlesubmit";
import styles from "./OrdersList.module.css";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
// import Card from "../../components/Card/Card";
// import { Link } from "react-router-dom";

export default function OrdersList({ navigation }) {
  const [quotes, setQuotes] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterState, setFilterState] = useState("");

  useEffect(() => {
    async function fetchData() {
      const tempQuotes = await getQuotes();
      setQuotes(tempQuotes);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.header}>
        <h5>ORDENES</h5>

        {/* Input de busqueda: */}

        <div className={styles.search}>
          <span className={styles.iconsearch}>
            <FiSearch />
          </span>
          <input
            className={styles.inputsearch}
            type="text"
            placeholder="Buscar por nro de cliente u órden"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* Fin input busqueda */}

        {/* Select para filtrar estado */}
        <select
          className={styles.select}
          value={filterState}
          onChange={(e) => setFilterState(e.target.value)}
        >
          <option value="">Filtrar por estado</option>
          <option value="Pendiente" selected>
            Pendiente
          </option>
          <option value="En proceso">En proceso</option>
          <option value="Terminado">Terminado</option>
        </select>
        {/* Fin select para filtrar estado */}
      </div>

      <Link to={"new-order"} style={{ textDecoration: "none" }}>
        <button>Agregar nueva orden</button>
      </Link>

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.column}>
            <p>Numero de cliente</p>
          </div>
          <div className={styles.column}>
            <p>Fecha de creación</p>
          </div>
          <div className={styles.column}>
            <p>Numero de orden</p>
          </div>
          <div className={styles.column}>
            <p>Validez</p>
          </div>
          <div className={styles.column}>
            <p>Estado</p>
          </div>
        </div>
        <div className={styles.divisor} />
        {quotes &&
          quotes
            .filter((quote) => {
              const searchValue = searchTerm.toLowerCase();
              const stateFilter = filterState.toLowerCase();
              const clientNumber = quote.client_number?.toLowerCase() || ""; // Acceso seguro
              const quoteNumber = quote.quote_number?.toLowerCase() || ""; // Acceso seguro
              const estado = quote.estado?.toLowerCase() || ""; // Acceso seguro

              return (
                (clientNumber.includes(searchValue) ||
                  quoteNumber.includes(searchValue)) &&
                (stateFilter === "" || estado === stateFilter)
              );
            })
            .map((quote) => (
              <>
                <div className={styles.tableRow}>
                  <div className={styles.firstColumn}>
                    <p>{quote.id_cliente}</p>
                  </div>
                  <div className={styles.column}>
                    <p>{quote.fecha_actual}</p>
                  </div>
                  <div className={styles.column}>
                    <p>{quote.numero_orden}</p>
                  </div>
                  <div className={styles.phoneColumn}>
                    <p>{quote.fecha_validez}</p>
                  </div>
                  <div>
                    <button>Editar</button>
                  </div>
                </div>

                <div className={styles.divisor} />
                {quote.items &&
                  quote.items.map((item) => {
                    console.log("item.estado:", item.estado);
                    console.log("filterState:", filterState);
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <p>ID:{item.id}</p>
                          <p>Descripcion: {item.description}</p>
                          <p>Entrega: {item.entrega}</p>
                          <p>Cantidad: {item.cantidad}</p>
                          <p>Precio: {item.precio}</p>
                          <select
                            className={styles.selectitemestado}
                            name="select"
                            value={item.estado}
                          >
                            <option value="Pendiente">Pendiente</option>
                            <option value="En proceso">En proceso</option>
                            <option value="Terminado">Terminado</option>
                          </select>
                        </div>
                        <div className={styles.divisor} />
                      </>
                    );
                  })}
              </>
            ))}
      </div>
    </>
  );
}
